import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS_BY_ID = gql`
  query getNotificationsById(
    $getNotificationsByIdArgs: GetNotificationsByIdArgs!
  ) {
    getNotificationsById(getNotificationsByIdArgs: $getNotificationsByIdArgs) {
      id
      userId
      title
      message
      read
      url
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_NOTIFICATION_BY_ID = gql`
  mutation updateNotificationById(
    $updateNotificationByIdArgs: UpdateNotificationByIdArgs!
  ) {
    updateNotificationById(
      updateNotificationByIdArgs: $updateNotificationByIdArgs
    )
  }
`

export const UPDATE_NOTIFICATION_ALL = gql`
  mutation updateNotificationAll(
    $updateNotificationAllArgs: UpdateNotificationAllArgs!
  ) {
    updateNotificationAll(updateNotificationAllArgs: $updateNotificationAllArgs)
  }
`
