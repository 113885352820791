import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from '../../logo.png'
import { getContext } from 'utils/store'
import { useForgotPasswordMutation } from 'graphql/gen-types'
import {
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Grid,
  Paper,
} from '@mui/material'
import { AlternateEmail } from '@mui/icons-material'

export function ForgotPasswordInputEmail(): JSX.Element {
  const navigate = useNavigate()
  const { setAlertMessage } = getContext()

  // MAIN STATE
  const [email, setEmail] = useState('')

  const [forgotPassword] = useForgotPasswordMutation()

  const handleSubmit = async (): Promise<void> => {
    if (!email) {
      setAlertMessage('Invalid email')
      return
    }

    forgotPassword({
      variables: {
        forgotPasswordArgs: {
          email,
        },
      },
    })
      .then(() => {
        setAlertMessage(
          'Success! Instructions to reset your password were sent to your email'
        )
        navigate('/')
      })
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="h3">Forgot your password?</Typography>
          <Typography variant="subtitle1">
            Type in your account email
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={logo}
            alt="resolvexLogo"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            elevation={5}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
              p: 2,
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="emailInput"
                label="Email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                  startAdornment: <AlternateEmail />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                id="forgot-password-submit-btn"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <NavLink to="/">
                <Typography variant="subtitle2">Back to Login</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
