import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { createGenerateClassName, StylesProvider } from '@mui/styles'
import { getClient, theme } from '@resolvex/resolvex-sdk'
import { Provider, Auth, AppRoute } from 'components'

import '@fullcalendar/common/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/daygrid/main.css'
import { SnackbarProvider } from 'notistack'

function ErrorFallback({ error }: FallbackProps): JSX.Element {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
    </div>
  )
}

function errorHandler(error: Error): void {
  console.log(error)
  alert('System Error please contact us')
}

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'front-end',
  seed: 'front-end',
})

export const App = (): JSX.Element => {
  return (
    <SnackbarProvider maxSnack={10}>
      <ApolloProvider client={getClient()}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
          <StyledEngineProvider injectFirst>
            <Provider>
              <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <BrowserRouter>
                    <Auth>
                      <AppRoute />
                    </Auth>
                  </BrowserRouter>
                </ThemeProvider>
              </StylesProvider>
            </Provider>
          </StyledEngineProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </SnackbarProvider>
  )
}

export default App
