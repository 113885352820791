import { gql } from '@apollo/client'

// Reason it's mutation is because of https://github.com/apollographql/react-apollo/issues/3499
export const AUTHENTICATE_USER = gql`
  mutation authenticateUser($authenticateUserArgs: AuthenticateUserArgs!) {
    authenticateUser(authenticateUserArgs: $authenticateUserArgs)
  }
`
export const VERIFY_USER_OTP = gql`
  mutation verifyUserOtp($verifyUserOtpArgs: VerifyUserOtpArgs!) {
    verifyUserOtp(verifyUserOtpArgs: $verifyUserOtpArgs)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordArgs: ResetPasswordArgs!) {
    resetPassword(resetPasswordArgs: $resetPasswordArgs)
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshTokenArgs: RefreshTokenArgs!) {
    refreshToken(refreshTokenArgs: $refreshTokenArgs)
  }
`

export const DELETE_TOKEN = gql`
  mutation deleteToken($deleteTokenArgs: DeleteTokenArgs!) {
    deleteToken(deleteTokenArgs: $deleteTokenArgs)
  }
`
