import { useNavigate } from 'react-router-dom'
import { Typography, Box, Button, Grid, Container } from '@mui/material'

export const NotAuthorized = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}
    >
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">
            Feature not authorized
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={() => navigate('/caseform/patients')}>
            Go back
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
