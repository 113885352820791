import { Dialog, Divider, Fade, Grid, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  teamsLink,
  phoneNumber,
  emailAddress,
  physicalAddress,
} from 'utils/variables/constants'
import { Email, FmdGood, Phone, SupportAgent } from '@mui/icons-material'
import logo from '../../logo.png'

interface ContactModalProps {
  state: {
    openState: boolean
    handleClose: () => void
  }
}

export function ContactModal({ state }: ContactModalProps): JSX.Element {
  const { openState, handleClose } = state

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openState}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={openState}>
        <Grid
          container
          component={Paper}
          sx={{
            p: 2,
          }}
        >
          <Grid item xs={10} />
          <Grid item xs={2}>
            <img style={{ width: '100%' }} src={logo} alt="resolvexLogo" />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" color="primary.main">
              Contact Us!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
          >
            <SupportAgent />
            <Typography variant="h6">On Teams:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">
              <Link to={teamsLink}>Microsoft Teams Link</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
          >
            <Phone />
            <Typography variant="h6">Call:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">{phoneNumber}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
          >
            <Email />
            <Typography variant="h6">Email:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">{emailAddress}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
          >
            <FmdGood />
            <Typography variant="h6">Address:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">{physicalAddress}</Typography>
          </Grid>
        </Grid>
      </Fade>
    </Dialog>
  )
}
