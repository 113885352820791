export const ANALYZE_HOST =
  process.env.REACT_APP_ANALYZE_HOST || 'http://localhost:3001'
export const ADMIN_HOST =
  process.env.REACT_APP_ADMIN_HOST || 'http://localhost:3002'
export const CASEFORM_HOST =
  process.env.REACT_APP_CASEFORM_HOST || 'http://localhost:3003'

export const phoneNumber = '(207) 557-5737'
export const emailAddress = 'tyler.castle@resolvex.com'
export const physicalAddress = 'Cary, NC.'
export const teamsLink =
  'https://teams.microsoft.com/_#/files/General?threadId=19:5ZHAd8IsTJWYp9ga1Bx-OyFgZK-R-TbdD61HFbVh0Kc1@thread.tacv2&ctx=channel'

// FEATURE FLAGS
export type InitFeatureFlagStatus = null | 'LOADING' | 'LOADED'

export const ANALYZE_FEATURE_FLAG = 'analytics-tab'
export const RESOURCE_FEATURE_FLAG = 'resource-tab'
export const REASSURANCE_FEATURE_FLAG = 'reassurance-tab'
export const TABLEAU_FEATURE_FLAG = 'tableau-tab'
export const ADMIN_TIMESHEET = 'admin-timesheet'

// ROUTER PATHS
export const URL_VERIFY_OTP = '/verify-resolvex-otp'

export const URL_CASEFORM_PATIENTS = '/caseform/contacts'
export const URL_CASEFORM_PATIENTS_ADD = '/caseform/contacts/add'
export const URL_CASEFORM_SESSIONS = '/caseform/sessions'
export const URL_CASEFORM_RESOURCES = '/caseform/resources'
export const URL_CASEFORM_RESOURCES_ADD = '/caseform/resources/add'
export const URL_CASEFORM_DOWNLOADS = '/caseform/downloads'

export const URL_ANALYTICS_GENERAL = '/analytics/general'
export const URL_ANALYTICS_RESOURCE = '/analytics/resource'
export const URL_ANALYTICS_REASSURANCE = '/analytics/reassurance'
export const URL_ANALYTICS_TABLEAU = '/analytics/tableau'

export const URL_ADMIN_BASIC = '/admin/basic'
export const URL_ADMIN_RESOLVEX = '/admin/resolvex'
export const URL_ADMIN_TIMESHEET = '/admin/timesheet'
export const URL_ADMIN_OTP = '/admin/otp'

export const isProd = process.env.NODE_ENV === 'production'

export const URL_ACCOUNT_SERVICE = process.env.REACT_APP_ACCOUNT_SERVICE_URI
  ? process.env.REACT_APP_ACCOUNT_SERVICE_URI
  : 'http://localhost:8083'
