import {
  Add,
  ChevronLeft,
  ChevronRight,
  Link,
  Search,
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material'
import { FormModal } from 'components/common/formModal'
import { useEffect, useMemo, useState } from 'react'
import { QuickLink, useGetQuicklinksLazyQuery } from 'graphql/gen-types'
import { getDecodedToken } from '@resolvex/resolvex-sdk'
import { QuickLinksModalNew } from './quickLinksModalNew'
import { QuickLinksListItem } from './quickLinksListItem'
import { QuickLinksListItemSkeleton } from './quickLinksListItemSkeleton'

export const QuickLinksModal = ({
  open = true,
  onClose = () => {
    return
  },
}: QuickLinksModalProps): JSX.Element => {
  const [keywordSearch, setKeywordSearch] = useState<string>('')
  const [showQuickLinkModal, setShowQuickLinkModal] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { clientName, role } = getDecodedToken()

  const [, { refetch, data, loading, error }] = useGetQuicklinksLazyQuery({
    variables: {
      getQuicklinksArgs: {
        clientName: clientName,
        keyword: '',
        page: page,
        itemPerPage: pageSize,
      },
    },
    fetchPolicy: 'network-only',
  })

  const quickLinksData: QuickLink[] = useMemo(() => {
    if (!loading && !error && data?.getQuicklinks) {
      return data.getQuicklinks
    }

    return []
  }, [data, loading, error])

  const refreshLinks = () =>
    refetch({
      getQuicklinksArgs: {
        clientName: clientName,
        keyword: keywordSearch,
      },
    })

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    refreshLinks()
  }

  useEffect(() => {
    refetch({
      getQuicklinksArgs: {
        clientName: clientName,
        keyword: keywordSearch,
        page: page,
        itemPerPage: pageSize,
      },
    })

    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    setPageSize(10)
    refetch()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {showQuickLinkModal && (
        <QuickLinksModalNew
          open={showQuickLinkModal}
          onClose={() => setShowQuickLinkModal(false)}
          onSubmit={() => {
            setShowQuickLinkModal(false)
            refreshLinks()
          }}
        />
      )}

      <FormModal
        title="Quick links"
        icon={<Link color="primary" />}
        open={open}
        width={640}
        onClose={onClose}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} component="form" onSubmit={handleSearchSubmit}>
            <TextField
              id="searchQuickLink"
              placeholder="Search..."
              value={keywordSearch}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <Search color="info" />,
              }}
              onChange={(e) => setKeywordSearch(e.target.value)}
              disabled={false}
              type="search"
              helperText={
                <Typography variant="caption" color="info.main">
                  Press ENTER to search
                </Typography>
              }
            />
          </Grid>
          {role.includes('ADMIN') && (
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                id="newQuickLinkBtn"
                variant="contained"
                size="medium"
                onClick={() => setShowQuickLinkModal(true)}
                endIcon={<Add />}
              >
                New link
              </Button>
            </Grid>
          )}

          <Grid item xs={10}></Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ButtonGroup>
              <Button
                size="small"
                onClick={() => setPage((prevState) => prevState - 1)}
                disabled={page <= 1}
              >
                <ChevronLeft />
              </Button>
              <Button
                size="small"
                onClick={() => setPage((prevState) => prevState + 1)}
                disabled={!(quickLinksData.length >= pageSize)}
              >
                <ChevronRight />
              </Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <List
              sx={{ height: '100%', maxHeight: '480px', overflowY: 'scroll' }}
            >
              {!loading &&
                quickLinksData.map((ql) => {
                  return (
                    <QuickLinksListItem
                      quickLink={ql}
                      key={ql.id + ql.title}
                      refetch={refetch}
                    />
                  )
                })}

              {loading &&
                Array.from({ length: pageSize }, (_, index) => index).map(
                  (index) => {
                    return <QuickLinksListItemSkeleton key={index} />
                  }
                )}
            </List>
          </Grid>
        </Grid>
      </FormModal>
    </>
  )
}

interface QuickLinksModalProps {
  open?: boolean
  onClose?: () => void
}
