/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext } from 'react'
import { MessagebarProps } from 'utils/interface/common'
import { InitFeatureFlagStatus } from './variables/constants'

export interface ContextInterface {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isOtpVerified: boolean
  setIsOtpVerified: React.Dispatch<React.SetStateAction<boolean>>
  alertMessage: string
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>
  handleLogout: (reloadTimer?: number) => void
  messagebarDetails: MessagebarProps
  setMessagebarDetails: React.Dispatch<React.SetStateAction<MessagebarProps>>
  currentPath: string
  navigate: (link: string) => void
  featureAccess: Record<string, boolean>
  hasFeatureAccess: (pathName: string) => boolean
  isInitFeatureFlagStatus: InitFeatureFlagStatus
  initializeFeatureFlag: () => Promise<void>
}

const StoreContext = createContext({} as ContextInterface)

const { Provider } = StoreContext

const getContext = (): ContextInterface => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useContext(StoreContext)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StoreProvider = ({ ...props }: any): JSX.Element => {
  return <Provider value={{ ...props }} {...props} />
}

export { StoreProvider, getContext }
