import { gql } from '@apollo/client'

export const GET_QUICKLINKS = gql`
  query getQuicklinks($getQuicklinksArgs: GetQuicklinksArgs!) {
    getQuicklinks(getQuicklinks: $getQuicklinksArgs) {
      id
      title
      url
      description
      createdById
      createdBy
      updatedById
      updatedBy
      createdAt
      updatedAt
    }
  }
`

export const GET_QUICKLINK_BY_ID = gql`
  query getQuicklinkById($getQuicklinkByIdArgs: GetQuicklinkByIdArgs!) {
    getQuicklinkById(getQuicklinkById: $getQuicklinkByIdArgs) {
      id
      title
      url
      description
      createdById
      createdBy
      updatedById
      updatedBy
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_QUICKLINK_BY_ID = gql`
  mutation updateQuickLinkById(
    $updateQuicklinkByIdArgs: UpdateQuicklinkByIdArgs!
  ) {
    updateQuickLinkById(updateQuickLinkById: $updateQuicklinkByIdArgs)
  }
`

export const ADD_QUICKLINKS = gql`
  mutation addQuickLink($addQuicklinkArgs: AddQuicklinkArgs!) {
    addQuickLink(addQuickLink: $addQuicklinkArgs)
  }
`

export const DELETE_QUICKLINK_BY_ID = gql`
  mutation deleteQuickLinkById(
    $deleteQuicklinkByIdArgs: DeleteQuicklinkByIdArgs!
  ) {
    deleteQuickLinkById(deleteQuickLinkById: $deleteQuicklinkByIdArgs)
  }
`
