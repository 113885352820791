import { useState } from 'react'
import logo from '../../logo.png'
import { useNavigate } from 'react-router-dom'
import { getContext } from 'utils/store'
import { RESET_PASSWORD } from 'graphql/loginGql'
import { useMutation } from '@apollo/client'
import {
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Grid,
  Paper,
} from '@mui/material'

export const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate()
  const { setAlertMessage } = getContext()
  const params = new URLSearchParams(document.location.search.substring(1))
  const email = params.get('email')?.replace(' ', '+')
  const token = params.get('token')

  // MAIN STATE
  const [mainPassword, setMainPassword] = useState<string>('')
  const [verifyPassword, setVerifyPassword] = useState<string>('')

  // Triggered when the user submits
  const [resetPassword] = useMutation(RESET_PASSWORD)

  const handleSubmit = async (): Promise<void> => {
    if (mainPassword !== verifyPassword) {
      setAlertMessage('Password and verify password does not match')
      return
    }
    if (!email || !token) {
      setAlertMessage('System Error')
      return
    }

    const { data } = await resetPassword({
      variables: {
        resetPasswordArgs: {
          email,
          password: mainPassword,
          token,
        },
      },
    })

    if (data.resetPassword) {
      navigate('/login')
      return
    }

    setAlertMessage('Failed to reset password, please contact us.')
  }

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="h3">Reset Password</Typography>
          <Typography variant="subtitle1">Set your password below</Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={logo}
            alt="resolvexLogo"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            elevation={5}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
              p: 2,
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                id="resetPasswordInput"
                label="Password"
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                }}
                onChange={(e) => setMainPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                id="outlined-verifyPassword"
                label="Verify Password"
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                }}
                onChange={(e) => setVerifyPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
