import { useMutation } from '@apollo/client'
import { Note, Link, Add } from '@mui/icons-material'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { getDecodedToken } from '@resolvex/resolvex-sdk'
import { FormModal } from 'components/common/formModal'
import { QuickLink } from 'graphql/gen-types'
import { ADD_QUICKLINKS } from 'graphql/quicklinkGql'
import { useState } from 'react'
import { validateURLField } from 'utils/helper/validations'
import { getContext } from 'utils/store'

export const QuickLinksModalNew = ({
  open = true,
  onClose = () => {
    return
  },
  onSubmit = () => {
    return
  },
}: QuickLinksModalNewProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [quickLink, setQuickLink] = useState<
    Pick<QuickLink, 'title' | 'url' | 'description'>
  >({ title: '', url: '', description: '' })

  const { setMessagebarDetails } = getContext()
  const { clientName, id: userId } = getDecodedToken()

  const [addQuickLink] = useMutation(ADD_QUICKLINKS)

  const handleUpdateField = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target
    setQuickLink((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (
    e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    if (e) {
      e.preventDefault()
    }

    try {
      setIsLoading(true)
      if (quickLink.url === '' || quickLink.title === '') {
        throw new Error('Title and URL are required.')
      }

      if (!validateURLField(quickLink.url)) {
        throw new Error('URL not valid')
      }

      await addQuickLink({
        variables: {
          addQuicklinkArgs: {
            ...quickLink,
            clientName: clientName,
            createdById: userId,
            updatedById: userId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        },
      })

      setMessagebarDetails({
        message: `Successfully added new link for ${quickLink.title}`,
        open: true,
        severity: 'success',
      })

      onSubmit()
    } catch (error) {
      setMessagebarDetails({
        message: (error as Error)?.message || 'Something went wrong',
        open: true,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => onClose()

  return (
    <FormModal open={open} onClose={onClose} width={560}>
      <Grid container component="form" spacing={1}>
        <Grid
          item
          xs={12}
          spacing={8}
          columnSpacing={8}
          gap={8}
          component="form"
        >
          <TextField
            id="quickLinkTitle"
            placeholder="Title"
            name="title"
            value={quickLink.title}
            color="primary"
            onChange={handleUpdateField}
            variant="standard"
            type="text"
            required
            fullWidth
            InputProps={{
              startAdornment: <Note color="info" />,
              sx: { fontSize: 16 },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="quickLinkURL"
            placeholder="URL"
            name="url"
            value={quickLink.url}
            color="primary"
            onChange={handleUpdateField}
            variant="standard"
            type="text"
            required
            fullWidth
            InputProps={{
              startAdornment: <Link color="info" />,
              sx: { fontSize: 12 },
            }}
            helperText="Format: http://www.link.com or http://link.com"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="quickLinkDescription"
            placeholder="Description"
            name="description"
            value={quickLink.description}
            color="primary"
            onChange={handleUpdateField}
            variant="standard"
            type="text"
            fullWidth
            multiline
            minRows={4}
            InputProps={{
              sx: { fontSize: 12 },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            type="button"
            fullWidth
            color="secondary"
            onClick={handleClose}
            variant="contained"
            size="large"
            disabled={isLoading}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            id="addQuickLinkSaveBtn"
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            size="large"
            endIcon={
              <>
                {isLoading && <CircularProgress size={24} />}
                {!isLoading && <Add />}
              </>
            }
            disabled={isLoading}
          >
            {isLoading && <>Adding...</>}
            {!isLoading && <>Add</>}
          </Button>
        </Grid>
      </Grid>
    </FormModal>
  )
}

interface QuickLinksModalNewProps {
  open?: boolean
  onClose?: () => void
  onSubmit?: () => void
}
