import { useEffect, useState } from 'react'
import { Socket, io, SocketOptions, ManagerOptions } from 'socket.io-client'

const useSocket = (
  url: string,
  options?: Partial<ManagerOptions & SocketOptions> | undefined
): Socket | null => {
  const [socket, setSocket] = useState<Socket | null>(null)

  useEffect(() => {
    const socketIo = io(url, {
      ...options,
      path: '/socket.io',
      reconnectionAttempts: 5, // Maximum number of reconnection attempts
      transports: ['websocket'], // Use WebSocket transport only
    })

    setSocket(socketIo)

    // Clean up the connection on unmount
    return () => {
      socketIo.disconnect()
    }
  }, [url, options])

  return socket
}

export default useSocket
