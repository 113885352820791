import { Snackbar } from '@mui/material'
import { getContext } from 'utils/store'
import { CustomAlert } from './alert'

export function Messagebar(): JSX.Element {
  const { messagebarDetails, setMessagebarDetails } = getContext()
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return
    setMessagebarDetails({ ...messagebarDetails, open: false })
  }

  return (
    <Snackbar
      open={messagebarDetails.open}
      autoHideDuration={6000}
      onClose={() => handleClose()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        <CustomAlert
          onClose={handleClose}
          severity={messagebarDetails.severity}
        >
          {messagebarDetails.message}
        </CustomAlert>
      </div>
    </Snackbar>
  )
}
