import { Check, Close, Dangerous, InfoOutlined } from '@mui/icons-material'
import {
  Modal,
  Fade,
  Button,
  Grid,
  Paper,
  Typography,
  Backdrop,
  Divider,
  Box,
} from '@mui/material'

interface modalProps {
  open: boolean
  onClose: () => void
  children: JSX.Element
  width?: string | number
  icon?: JSX.Element
  title?: string | JSX.Element
  color?:
    | 'inherit'
    | 'primary'
    | 'info'
    | 'warning'
    | 'success'
    | 'error'
    | 'secondary'
}

export const FormModal = ({
  open,
  onClose,
  children,
  title,
  width,
  icon,
  color = 'info',
}: modalProps): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid
          component={Paper}
          container
          marginTop={0}
          spacing={1}
          sx={{
            minWidth: 360,
            width: width ? width : 'max-content',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.default',
            border: '2px solid',
            borderColor: `${color}.main`,
            boxSizing: 'content-box',
            p: 1,
            maxHeight: '95vh',
            overflow: 'auto',
          }}
        >
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {icon && icon}
            {!icon && (
              <>
                {color === 'inherit' && (
                  <InfoOutlined color={color} fontSize="large" />
                )}

                {color === 'info' && (
                  <InfoOutlined color={color} fontSize="large" />
                )}
                {color === 'primary' && (
                  <InfoOutlined color={color} fontSize="large" />
                )}
                {color === 'secondary' && (
                  <InfoOutlined color={color} fontSize="large" />
                )}

                {color === 'success' && (
                  <Check color={color} fontSize="large" />
                )}

                {color === 'error' && (
                  <Dangerous color={color} fontSize="large" />
                )}
                {color === 'warning' && (
                  <Dangerous color={color} fontSize="large" />
                )}
              </>
            )}
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" align="left" sx={{ width: '100%' }}>
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              tabIndex={-1}
              sx={{ p: 1, minWidth: '0' }}
              variant="contained"
              size="medium"
              color={color}
              id="formModalClose"
              onClick={() => onClose()}
            >
              <Close />
            </Button>
          </Grid>

          <Grid
            component={Box}
            item
            xs={12}
            sx={{
              p: 1,
            }}
          >
            <Divider sx={{ width: '100%' }} />
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  )
}
