import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface PageHeaderInterface {
  page: string
}

export function PageHeader({ page }: PageHeaderInterface): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.pageHeader}>
      <h1 className={classes.pageTitle}>{page}</h1>
      <div className={classes.pageIcons}></div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    width: '1350px',
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageTitle: {
    fontFamily: theme.typography.body1.fontFamily,
  },
  pageIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: '10px',
  },
}))
