import { Box, LinearProgress } from '@mui/material'
import { useEffect } from 'react'

interface MicroFrontendInterface {
  name: 'Analyze' | 'Admin' | 'Caseform'
  host: string
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderAnalyze: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unmountAnalyze: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderAdmin: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unmountAdmin: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderCaseform: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unmountCaseform: any
  }
}

export const MicroFrontend = ({
  name,
  host,
}: MicroFrontendInterface): JSX.Element => {
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`
    const renderMicroFrontend = () => {
      window[`render${name}`](`${name}-container`)
    }

    if (document.getElementById(scriptId)) {
      renderMicroFrontend()
      return
    }

    fetch(`${host}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script')
        script.id = scriptId
        script.crossOrigin = ''
        script.src = `${host}${manifest.files['main.js']}`
        script.onload = () => {
          renderMicroFrontend()
        }
        document.head.appendChild(script)
      })

    return () => {
      if (document.getElementById(`${name}-container`)) {
        window[`unmount${name}`] &&
          window[`unmount${name}`](`${name}-container`)
      }
    }
  })

  return (
    <Box id={`${name}-container`}>
      <LinearProgress />
    </Box>
  )
}

MicroFrontend.defaultProps = {
  document,
  window,
}
