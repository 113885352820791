import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../logo.png'
import { saveToken } from '@resolvex/resolvex-sdk'
import { getContext } from 'utils/store'
import { TermsComponent } from 'components/termsAndCondition/terms'
import { PrivacyComponent } from 'components/termsAndCondition/privacy'
import { useMutation } from '@apollo/client'
import { AUTHENTICATE_USER } from 'graphql/loginGql'
import {
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  Container,
} from '@mui/material'
import {
  AlternateEmail,
  Lock,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { URL_VERIFY_OTP } from 'utils/variables/constants'

export const Login = (): JSX.Element => {
  const { setIsAuthenticated, setAlertMessage, navigate } = getContext()

  // MAIN STATE
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordShow, setPasswordShow] = useState(false)
  const [termsOpen, setTermsOpen] = useState<boolean>(false)
  const [privacyOpen, setPrivacyOpen] = useState<boolean>(false)

  // Reason it's mutation is because of https://github.com/apollographql/react-apollo/issues/3499
  const [authenticate] = useMutation(AUTHENTICATE_USER)

  const handleSubmit = async (): Promise<void> => {
    if (!email || !password) {
      setAlertMessage('Invalid username/password')
      return
    }
    try {
      const { data } = await authenticate({
        variables: {
          authenticateUserArgs: {
            email,
            password,
          },
        },
      })

      const token = data?.authenticateUser

      if (token) {
        saveToken(token)
        setIsAuthenticated(true)
        navigate(URL_VERIFY_OTP)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlertMessage(error.message)
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <TermsComponent dialogOpen={termsOpen} setDialogOpen={setTermsOpen} />
      <PrivacyComponent
        dialogOpen={privacyOpen}
        setDialogOpen={setPrivacyOpen}
      />
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="h3" color="primary.main">
            Sign in to your Dashboard
          </Typography>
          <Typography variant="subtitle1">Enter details below</Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={logo}
            alt="resolvexLogo"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            elevation={5}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 2,
            }}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="emailInput"
                label="Email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                  startAdornment: <AlternateEmail />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={passwordShow ? 'text' : 'password'}
                id="passwordInput"
                label="Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                  startAdornment: <Lock />,
                  endAdornment: passwordShow ? (
                    <VisibilityOff
                      onClick={() => setPasswordShow(false)}
                      sx={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <Visibility
                      onClick={() => setPasswordShow(true)}
                      sx={{ cursor: 'pointer' }}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <NavLink to="/forgotPassword">
                <Typography variant="subtitle2">Forgot Password</Typography>
              </NavLink>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="loginInput"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit()}
                fullWidth
              >
                Sign In
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption">
                By using our app you automatically agree to our{' '}
                <Link to="#" onClick={() => setTermsOpen(true)}>
                  terms
                </Link>{' '}
                and{' '}
                <Link to="#" onClick={() => setPrivacyOpen(true)}>
                  privacy
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
