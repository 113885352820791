import { useState } from 'react'
import { ContextInterface, StoreProvider } from 'utils/store'

import { useDeleteTokenMutation } from 'graphql/gen-types'
import { getToken, useWindowLocation } from '@resolvex/resolvex-sdk'
import { AlertDialog } from 'components/common/alertDialog'
import { Messagebar } from 'components/common/messagebar'
import { MessagebarProps } from 'utils/interface/common'
import { UseFeatureAccess } from './utilities/useFeatureAccess'
import { hardRedirectTo } from 'utils/helper/functions'

interface ProviderInterface {
  children: JSX.Element
}

export const Provider = ({ children }: ProviderInterface): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [deleteToken] = useDeleteTokenMutation()
  const { navigate, currentPath } = useWindowLocation()

  const token = getToken()

  // handlers
  const handleLogout = async (reloadTimer?: number) => {
    deleteToken({
      variables: {
        deleteTokenArgs: {
          token,
        },
      },
    })

    sessionStorage.clear()
    setIsAuthenticated(false)
    setIsOtpVerified(false)
    hardRedirectTo('/login', reloadTimer)
  }

  const [messagebarDetails, setMessagebarDetails] = useState<MessagebarProps>({
    open: false,
    message: 'There was an error!',
    severity: 'error',
  })

  const initialState: ContextInterface = {
    isAuthenticated,
    setIsAuthenticated,
    isOtpVerified,
    setIsOtpVerified,
    alertMessage,
    setAlertMessage,
    handleLogout,
    messagebarDetails,
    setMessagebarDetails,
    currentPath,
    navigate,
    ...UseFeatureAccess(),
  }

  return (
    <StoreProvider value={initialState}>
      {alertMessage.length !== 0 && (
        <AlertDialog alert={alertMessage} setAlert={setAlertMessage} />
      )}
      <Messagebar />
      {children}
    </StoreProvider>
  )
}
