import { Theme } from '@emotion/react'
import { Circle, ChevronRight } from '@mui/icons-material'
import {
  SxProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  ListItemButton,
  Typography,
} from '@mui/material'

interface NavBarListItemInterface {
  open: boolean
  index: number
  linkText: string
  linkTextIcon: JSX.Element
  isEnabled?: boolean
  subList?: {
    label: string
    url: string
    icon?: JSX.Element
    isEnabled?: boolean
    isHidden?: boolean
  }[]
  customMenu?: JSX.Element[]
  handleSubMenuOpen: (linkText: string) => void
  handleRouteChange: (link: string) => void
}

export const NavBarListItem = ({
  open,
  index,
  linkText,
  linkTextIcon,
  customMenu,
  isEnabled = true,
  subList,
  handleSubMenuOpen,
  handleRouteChange,
}: NavBarListItemInterface): JSX.Element => {
  const isSubMenuOpen = open
  const hasubMenuList = subList && subList?.length > 0

  const subMenutransformValue = isSubMenuOpen ? 'rotate(90deg)' : ''
  const subMenuListDefaultIconStyle = {
    fontSize: 4,
    color: 'primary.light',
  } as SxProps<Theme>
  const subMenuListDefaultIcon = <Circle sx={subMenuListDefaultIconStyle} />
  const onClickHandler = () =>
    hasubMenuList ? handleSubMenuOpen(linkText) : handleRouteChange(linkText)

  return (
    <>
      <ListItemButton
        id={linkText}
        key={index + linkText}
        disabled={!isEnabled}
        onClick={() => onClickHandler()}
      >
        <ListItemIcon key={index + linkText}>{linkTextIcon}</ListItemIcon>

        <ListItemText primary={linkText} />
        <ChevronRight
          sx={{
            color: 'primary.light',
            transform: subMenutransformValue,
          }}
        />
      </ListItemButton>
      <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {subList &&
            subList.map((item, index) => {
              const isHidden =
                item.isHidden === undefined ? false : !item.isHidden

              if (isHidden) return <></>
              return (
                <ListItemButton
                  id={`${linkText}_${item.label}`}
                  disabled={!item.isEnabled}
                  key={index + linkText + item.url}
                  sx={{
                    pl: 3,
                  }}
                  onClick={() => handleRouteChange(item.url)}
                >
                  <ListItemIcon>
                    {item.icon ? item.icon : subMenuListDefaultIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color="primary.light">
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              )
            })}
          {customMenu?.map((customMenu, index) => {
            return <ListItem key={'customMenu' + index}>{customMenu}</ListItem>
          })}
        </List>
      </Collapse>
    </>
  )
}
