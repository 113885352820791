import { useNavigate } from 'react-router-dom'
import { Typography, Box, Button, Grid, Container } from '@mui/material'
import { URL_CASEFORM_SESSIONS } from 'utils/variables/constants'

export const PageNotFound = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}
    >
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">
            Page not found
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={() => navigate(URL_CASEFORM_SESSIONS)}>
            Go back
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
