import { useMutation } from '@apollo/client'
import { Note, Delete, Edit, Save, Link as LinkIcon } from '@mui/icons-material'
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  ListItem,
  Tooltip,
  Link,
  CircularProgress,
} from '@mui/material'
import { getDecodedToken } from '@resolvex/resolvex-sdk'
import { QuickLink } from 'graphql/gen-types'
import {
  DELETE_QUICKLINK_BY_ID,
  UPDATE_QUICKLINK_BY_ID,
} from 'graphql/quicklinkGql'
import { useState } from 'react'
import { validateURLField } from 'utils/helper/validations'
import { getContext } from 'utils/store'

export const QuickLinksListItem = ({
  quickLink,
  refetch = () => {
    return
  },
}: QuickLinksListItemProp): JSX.Element => {
  const [quickLinkFields, setQuickLinkFields] = useState<QuickLink>(quickLink)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { role, clientName, id: userId } = getDecodedToken()
  const { setMessagebarDetails } = getContext()

  const [updateQuickLinkById] = useMutation(UPDATE_QUICKLINK_BY_ID)
  const [deleteQuickLinkById] = useMutation(DELETE_QUICKLINK_BY_ID)

  const handleUpdateField = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target
    setQuickLinkFields((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSaveClick = async () => {
    try {
      setIsLoading(true)
      if (quickLinkFields.url === '' || quickLinkFields.title === '') {
        throw new Error('Title and URL are required.')
      }

      if (!validateURLField(quickLinkFields.url)) {
        throw new Error('URL not valid')
      }

      await updateQuickLinkById({
        variables: {
          updateQuicklinkByIdArgs: {
            clientName: clientName,
            id: quickLinkFields.id,
            title: quickLinkFields.title,
            url: quickLinkFields.url,
            description: quickLinkFields.description,
            updatedById: userId,
            updatedAt: new Date().toISOString(),
          },
        },
      })

      setMessagebarDetails({
        message: `Successfully updated link for ${quickLinkFields.title}`,
        open: true,
        severity: 'success',
      })
      setIsEditing(false)
      refetch()
    } catch (error) {
      setMessagebarDetails({
        message: (error as Error)?.message || 'Something went wrong',
        open: true,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteClick = async () => {
    try {
      setIsLoading(true)
      await deleteQuickLinkById({
        variables: {
          deleteQuicklinkByIdArgs: {
            clientName: clientName,
            id: quickLinkFields.id,
          },
        },
      })

      setMessagebarDetails({
        message: `Successfully deleted link for ${quickLinkFields.title}`,
        open: true,
        severity: 'success',
      })
      setIsEditing(false)
      refetch()
    } catch (error) {
      setMessagebarDetails({
        message: (error as Error)?.message || 'Something went wrong',
        open: true,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={9}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: isEditing ? 2 : 1,
            }}
          >
            {!isEditing && (
              <Tooltip
                title={`${quickLinkFields.title} - ${quickLinkFields.description}`}
                placement="left"
              >
                <>
                  <Typography
                    variant="h6"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {quickLinkFields.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="primary.main"
                    sx={{
                      cursor: 'pointer',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <Link href={quickLinkFields.url} target="_blank">
                      {quickLinkFields.url}
                    </Link>
                  </Typography>
                  <Typography
                    variant="caption"
                    color="info.main"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {quickLinkFields.description}
                  </Typography>
                </>
              </Tooltip>
            )}

            {isEditing && (
              <>
                <TextField
                  id="quickLinkTitle"
                  placeholder="Title"
                  name="title"
                  value={quickLinkFields.title}
                  color="primary"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    startAdornment: <Note color="info" />,
                    sx: { fontSize: 16 },
                  }}
                  onChange={handleUpdateField}
                />

                <TextField
                  id="quickLinkURL"
                  placeholder="URL"
                  name="url"
                  value={quickLinkFields.url}
                  color="primary"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    startAdornment: <LinkIcon color="info" />,
                    sx: { fontSize: 12 },
                  }}
                  onChange={handleUpdateField}
                  helperText="Format: http://www.link.com or http://link.com"
                />

                <TextField
                  id="quickLinkDescription"
                  placeholder="Description"
                  name="description"
                  value={quickLinkFields.description}
                  color="primary"
                  variant="standard"
                  fullWidth
                  multiline
                  minRows={4}
                  InputProps={{
                    sx: { fontSize: 12 },
                  }}
                  onChange={handleUpdateField}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          {role.includes('ADMIN') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {!isEditing && (
                <Button
                  disableElevation
                  variant="contained"
                  aria-label="edit"
                  size="small"
                  color="primary"
                  disabled={isLoading}
                  onClick={() => setIsEditing(true)}
                >
                  <Edit />
                </Button>
              )}

              {isEditing && (
                <Button
                  disableElevation
                  variant="contained"
                  aria-label="edit"
                  size="small"
                  color="primary"
                  disabled={isLoading}
                  onClick={() => handleSaveClick()}
                >
                  <Save />
                </Button>
              )}

              <Button
                disableElevation
                variant="contained"
                aria-label="delete"
                size="small"
                color="error"
                disabled={isLoading}
                onClick={() => handleDeleteClick()}
              >
                <Delete />
              </Button>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress
                  size={24}
                  sx={{ opacity: isLoading ? 1 : 0 }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ListItem>
  )
}

interface QuickLinksListItemProp {
  quickLink: QuickLink
  refetch?: () => void
}
