import { Backdrop, Typography, CircularProgress } from '@mui/material'

interface AuthFeatureProgressInterface {
  open: boolean
}

export const AuthFeatureProgress = ({
  open,
}: AuthFeatureProgressInterface): JSX.Element => {
  return (
    <Backdrop
      open={open}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        opacity: 0.5,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
        }}
        color="info.main"
        variant="h5"
      >
        Setting up features. Please wait...
        <CircularProgress size={48} />
      </Typography>
    </Backdrop>
  )
}
