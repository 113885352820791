import { isAllowed } from '@resolvex/resolvex-sdk'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ANALYZE_FEATURE_FLAG, ANALYZE_HOST } from 'utils/variables/constants'
import { MicroFrontend } from './microFrontend'

export const AnalyzeMicroFrontend = (): JSX.Element => {
  const [allow, setAllow] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    isAllowed(ANALYZE_FEATURE_FLAG).then((allowed) => {
      if (allowed) {
        setAllow(true)
      } else {
        navigate('/caseform')
      }
    })
  }, [navigate])

  return allow ? (
    <MicroFrontend host={ANALYZE_HOST} name="Analyze" />
  ) : (
    <div>Permission Denied</div>
  )
}
