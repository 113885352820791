import { ListItem, Grid, Box, Skeleton } from '@mui/material'

export const QuickLinksListItemSkeleton = (): JSX.Element => {
  return (
    <ListItem>
      <Grid container>
        <Grid item xs={9}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Skeleton
              variant="text"
              width="100%"
              height={24}
              animation="wave"
            />
            <Skeleton
              variant="text"
              width="100%"
              height={16}
              animation="wave"
            />
            <Skeleton
              variant="text"
              width="100%"
              height={48}
              animation="wave"
            />
          </Box>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </ListItem>
  )
}
