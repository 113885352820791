import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { NavBar } from 'components/navBar'
import {
  getDecodedToken,
  isTokenExpired,
  getToken,
  setSelectedClientName,
  setSelectedClientId,
  getSelectedClientName,
  getSelectedClientId,
  useWindowLocation,
} from '@resolvex/resolvex-sdk'

import { getContext } from 'utils/store'
import { Box, CssBaseline, IconButton } from '@mui/material'
import { Header } from './header'
import { useWindowScroll } from './utilities/useWindowScroll'
import { ArrowDropUp } from '@mui/icons-material'
import { AuthFeatureProgress } from './authFeatureProgress'
import {
  URL_ADMIN_OTP,
  URL_CASEFORM_SESSIONS,
  URL_VERIFY_OTP,
} from 'utils/variables/constants'
import { hardRedirectTo } from 'utils/helper/functions'

interface AuthInterface {
  children: JSX.Element
}

export const Auth = ({ children }: AuthInterface): JSX.Element => {
  const { navigate } = getContext()
  const { scrolled, scrollTop } = useWindowScroll()
  const token = getToken()

  const { id, clientName, clientId, otpEnabled, otpVerified } =
    getDecodedToken()
  const location = useLocation()
  const { windowLocationRef } = useWindowLocation()
  const [tokenChecked, setTokenChecked] = useState<boolean>(false)

  const {
    isAuthenticated,
    setIsAuthenticated,
    isOtpVerified,
    setIsOtpVerified,
    hasFeatureAccess,
    isInitFeatureFlagStatus,
    initializeFeatureFlag,
    setAlertMessage,
  } = getContext()

  // cookie
  useEffect(() => {
    if (!isAuthenticated && !tokenChecked) {
      console.log('token check')

      const hasToken = id !== 0

      if (hasToken) {
        console.log(
          `Token Values <-- ${hasToken}, ${otpEnabled}, ${otpVerified} -->`
        )

        setIsAuthenticated(true)
        setIsOtpVerified(!!otpVerified)
        setSelectedClientName(getSelectedClientName())
        setSelectedClientId(getSelectedClientId())
      }

      setTokenChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // auth
  useEffect(() => {
    if (!tokenChecked) return
    const isInitialState = id === 0

    // console.log('authing')
    // console.log(location.pathname, isAuthenticated, id)

    // no cookie
    if (!isAuthenticated && isInitialState) {
      if (
        location.pathname === '/resetPassword' ||
        location.pathname === '/forgotPassword' ||
        location.pathname === '/login'
      ) {
        return
      }

      hardRedirectTo('/login')
      return
    }

    // expired cookie -- todo: refactor. add exp on getDecodedToken
    if (!isInitialState && isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const expired = isTokenExpired()
      if (expired) {
        window.stop()
        sessionStorage.clear()
        setAlertMessage('Session Expired')
        hardRedirectTo('/login', 1000)
        return
      }
    }

    // funcs below will need a real user
    if (isInitialState) {
      return
    }

    // otp not set
    if (isAuthenticated && !otpEnabled && location.pathname !== URL_ADMIN_OTP) {
      navigate(URL_ADMIN_OTP)
      return
    }

    // otp unverified
    if (
      isAuthenticated &&
      otpEnabled &&
      !isOtpVerified &&
      location.pathname !== URL_VERIFY_OTP
    ) {
      navigate(URL_VERIFY_OTP)
      return
    }

    // fetch main page
    if (isAuthenticated && otpVerified && isInitFeatureFlagStatus === null) {
      console.log('init ff')
      setSelectedClientName(getSelectedClientName() || clientName)
      setSelectedClientId(getSelectedClientId() || clientId)
      initializeFeatureFlag()
      return
    }

    // main page fetched
    if (
      isAuthenticated &&
      otpEnabled &&
      otpVerified &&
      isInitFeatureFlagStatus === 'LOADED'
    ) {
      if (!hasFeatureAccess(location.pathname)) {
        console.log('uaf')
        return navigate(URL_CASEFORM_SESSIONS)
      }

      const pathname =
        windowLocationRef.current.current_path || location.pathname

      if (VERIFIED_RESTRICTED_PATHS.includes(pathname)) {
        return navigate(URL_CASEFORM_SESSIONS)
      }

      console.log('authd')
      setIsAuthenticated(isAuthenticated)
      setIsOtpVerified(otpVerified)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    setIsAuthenticated,
    setIsOtpVerified,
    location,
    isOtpVerified,
    otpEnabled,
    otpVerified,
    isAuthenticated,
    isInitFeatureFlagStatus,
    navigate,
    clientName,
    clientId,
    initializeFeatureFlag,
    hasFeatureAccess,
    tokenChecked,
    windowLocationRef,
  ])

  const isVerificationComplete = isInitFeatureFlagStatus === 'LOADED'

  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        display: 'flex',
        minWidth: '100%',
      }}
      id="mainContainer"
    >
      {isVerificationComplete && <NavBar />}

      <AuthFeatureProgress open={isInitFeatureFlagStatus === 'LOADING'} />

      <Box
        component="main"
        sx={{
          m: 0,
          p: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CssBaseline />
        {isVerificationComplete && <Header />}
        {children}

        <IconButton
          id="scrollToTopButton"
          size="small"
          sx={{
            p: 0,
            bottom: 8,
            right: 8,
            position: 'fixed',
            zIndex: 999,
            transition: 'all 0.3s',
            pointerEvents: scrolled ? 'auto' : 'none',
            opacity: scrolled ? 1 : 0,
            backgroundColor: 'background.default',
            color: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
            },
            boxShadow: (theme) =>
              `0px 4px 16px ${theme.palette.primary.main}66`,
          }}
          color="secondary"
          onClick={() => scrollTop()}
        >
          <ArrowDropUp sx={{ fontSize: 32 }} color="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}

const VERIFIED_RESTRICTED_PATHS = [
  '/',
  '/login',
  '/clients',
  URL_VERIFY_OTP,
  URL_ADMIN_OTP,
]
