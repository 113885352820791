import { useEffect, useState } from 'react'

export const useWindowScroll = (): {
  scrolled: boolean
  scrollTop: () => void
} => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll)
    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [])

  const onWindowScroll = () => {
    const hasScrolled = window.scrollY > 135
    setScrolled(hasScrolled)
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return { scrolled, scrollTop }
}
